import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Excuse-A-Day",
  "date": "2015-01-07T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://twitter.com/songadaymann"
      }}>{`Jonathan Mann`}</a>{`, famous for his song-a-day project recently `}<a parentName="p" {...{
        "href": "http://youtube.com/watch?v=_8M6nEMVVOk"
      }}>{`made this video`}</a><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` telling the story of Jack Dolsen, a guy who decided to parody Jonathan’s work. Instead of making a song a day, he would find a new excuse every day for `}<em parentName="p">{`not`}</em>{` singing.`}</p>
    <p>{`What’s funny is that, making excuses for not doing something everyday takes as much time as just doing it. I won’t spoil the ending of the video, but it teaches a great lesson that any kind of creative person should learn.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`This also shows an interesting way of doing storytelling through videos online. I’d love to see more people take this approach.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      